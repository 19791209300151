// In src/Page.js

import { useWindowSize } from '../helpers'
import React, { useEffect, useContext } from 'react'
import Imgix, { Picture, Source } from 'react-imgix'
import { useSelector } from 'react-redux'
import Quote from '../components/Elements/Quote'
import BodyWrapper from '../components/Styled/BodyWrapper'
import ProductLoop from '../Containers/ProductLoop'
import { Box, ResponsiveContext, Image } from 'grommet'
import Layout from '../components/Global/Layout'

// const thisClient = client;

const quoteObject = {
  quote: '"The Etch-A-Sketch for the digital age."',
  author: 'Dezeen Magazine',
}

const ShopPage = ({ loading, history, ...rest }) => {
  const products = useSelector(state => state.cart.products)
  const size = useContext(ResponsiveContext)

  // let devices = products.filter((product) => product.productType === "Devices");
  let consumables = products.filter(
    product => product.productType === 'Consumables'
  )
  let addons = products.filter(
    product =>
      product.productType === 'Addons' ||
      product.productType === 'Accessories' ||
      product.productType === 'Subscription'
  )

  useEffect(() => {
    document.body.classList.add('shop')
  }, [])

  return (
    <Layout>
      <BodyWrapper>
        <Box
          style={{ position: 'relative', overflow: 'hidden' }}
          height={size !== 'small' ? '40vh' : '70vh'}
          fill={'horizontal'}
          direction={size !== 'small' ? 'row' : 'column-reverse'}
        >
          <Box style={{ position: 'absolute', bottom: 0 }}>
            <Picture>
              <Source
                src={
                  'https://jotowebsite.imgix.net/easyfill_hand_3.jpg?fit=crop&fp-x=.925&fp-y=.725&fp-z=2'
                }
                htmlAttributes={{ media: '(min-width: 1024px)' }}
                imgixParams={{ ar: '6:4' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/easyfill_hand_3.jpg?fit=crop&fp-x=.925&fp-y=.725&fp-z=2'
                }
                htmlAttributes={{ media: '(min-width: 768px)' }}
                imgixParams={{ ar: '1:1.1' }}
              />
              <Source
                src={
                  'https://jotowebsite.imgix.net/easyfill_hand_3.jpg?fit=crop&fp-x=.850&fp-y=.45&fp-z=5'
                }
                imgixParams={{ ar: '3:5' }}
                htmlAttributes={{ media: '(min-width: 320px)' }}
              />
              <Imgix
                src={
                  'https://jotowebsite.imgix.net/easyfill_hand_3.jpg?fit=crop&fp-x=.925&fp-y=.45&fp-z=5'
                }
                imgixParams={{ ar: '9:14' }}
              />
            </Picture>
          </Box>
        </Box>

        {addons && (
          <ProductLoop
            title={'Get the most out of Joto'}
            subtitle={'Addons'}
            type={'addons'}
            products={addons}
            {...rest}
          />
        )}
        <Quote {...quoteObject} {...rest} />
        {consumables && (
          <ProductLoop type={'consumables'} products={consumables} {...rest} />
        )}
      </BodyWrapper>
    </Layout>
  )
}

export default ShopPage

// <Picture style={{ position: "absolute" }}>
// <Source
//   src={
//     "https://jotowebsite.imgix.net/easyfill_hand_3.jpg?fit=crop&fp-x=.925&fp-y=.725&fp-z=2"
//   }
//   htmlAttributes={{ media: "(min-width: 1024px)" }}
//   imgixParams={{ ar: "6:3" }}
// />
// <Source
//   src={
//     "https://jotowebsite.imgix.net/easyfill_hand_3.jpg?fit=crop&fp-x=.925&fp-y=.725&fp-z=2"
//   }
//   htmlAttributes={{ media: "(min-width: 768px)" }}
//   imgixParams={{ ar: "1:1.1" }}
// />
// <Source
//   src={
//     "https://jotowebsite.imgix.net/easyfill_hand_3.jpg?fit=crop&fp-x=.850&fp-y=.45&fp-z=5"
//   }
//   imgixParams={{ ar: "3:5" }}
//   htmlAttributes={{ media: "(min-width: 320px)" }}
// />
// <Imgix
//   src={
//     "https://jotowebsite.imgix.net/easyfill_hand_3.jpg?fit=crop&fp-x=.925&fp-y=.45&fp-z=5"
//   }
//   imgixParams={{ ar: "9:14" }}
// />
// </Picture>
// <Text white>
// <Bullet color={colors.purple}>NOW AVAILABLE</Bullet>
// <Title>Easy Fill Kit</Title>
// <Body bold>
//   The new Joto Easy Fill kit is now available in limited quantities
//   to all our Kickstarter backers, Indiegogo backers and early
//   customers.
//   <br />
// </Body>
// <Emphasis>Second batch now available</Emphasis>
// {/* <Button color={colors.purple} onClick={() => window.location.href = "https://jotoshop.myshopify.com/cart/21995621253238:1,19709658857590:1,31336341438582:1?channel=buy_button"}>Preorder for backers</Button> */}
// </Text>
